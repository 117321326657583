<template>
  <div>
    <dashboard-page-title  :showAddBtn="false" :showFilter="false" :showSearch="false" :showExportation="false">المستندات</dashboard-page-title>

    <main-table :fields="fields" list_url="acceptance-docs" :baseURL="VUE_APP_VETE_LINK"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { VUE_APP_VETE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_VETE_LINK,
      fields: [
        { label: 'Id', key: 'id', class: 'text-right', sortable: true },
        { label: 'الاسم', key: 'title', class: 'text-right' },
        { label: 'نوع الملف', key: 'type', class: 'text-right' },
        { label: 'مطلوب من', key: 'required_for', class: 'text-right' },
        { label: 'ضروري/اختياري', key: 'is_required', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'edit',
              icon: 'ri-ball-pen-fill',
              color: 'primary',
              ifNavigate: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    addMerchant () {
    }
  }
}
</script>
